import { Link } from "gatsby";
import React, { useState } from "react";

import Contacts from "../constants/contacts";
import ProfileTypes from "../constants/profileTypes";
import Themes from "../constants/themes";

interface IContactsFormProps {
	profileType: ProfileTypes;
}

const ContactsForm = ({ profileType }: IContactsFormProps) => {
	const [personalAgree, setPersonalAgree] = useState(true);
	const themeProfile = Themes[profileType] || Themes[ProfileTypes.Organization];

	return (
		<section className="container contacts">
			<h2 className="contacts__title">Остались вопросы?</h2>
			<p>Опишите вашу проблему, и администратор свяжется с Вами в ближайшее время</p>
			<form className="form contacts__form" action={Contacts.serviceLink} method="POST">
				<div>
					<input type="text" name="_replyto" placeholder="E-mail" />
					<textarea name="message" placeholder="Ваш вопрос" />
				</div>
				<div>
					<button
						type="submit"
						className={["button", themeProfile.background].join(" ")}
						disabled={!personalAgree}
					>
						Отправить сообщение
					</button>
					<div className="contacts__personal-data">
						<label>
							<input
								type="checkbox"
								checked={personalAgree}
								onChange={() => setPersonalAgree(!personalAgree)}
							/>
							<span
								className={[
									"checkbox",
									personalAgree ? themeProfile.background : "",
									personalAgree ? themeProfile.border : ""
								].join(" ")}
							/>
						</label>
						<p>
							Отправляя данную форму, Вы даете согласие на обработку своих{" "}
							<Link to="/privacy" className={themeProfile.color}>
								персональных данных
							</Link>
						</p>
					</div>
				</div>
			</form>
		</section>
	);
};

export default ContactsForm;
