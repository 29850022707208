import { Link } from "gatsby";
import React from "react";

import appStore from "../../static/images/appstore.png";
import buttomBg from "../../static/images/button_bg.png";
import googlePlay from "../../static/images/googleplay.png";
import phoneBg from "../../static/images/phone_bg.png";

import Contacts from "../constants/contacts";

interface IBannerProps {
	elementSelectorForScroll: string;
}

const scrollToElement = (elementSelectorForScroll: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
	const element = document.querySelector(elementSelectorForScroll);
	if (element) {
		element.scrollIntoView({
			behavior: "smooth",
			block: "start"
		});
	}
	e.preventDefault();
};

const Banner = ({ elementSelectorForScroll }: IBannerProps) => (
	<section className="banner">
		<div className="container">
			<div className="banner__content">
				<h1 className="banner__title">Приложение «Стань чемпионом»</h1>
				<p>
					Помогает спортсменам и тренерам находить друг друга. Помогает фитнес центрам, федерациям спорта,
					центрам ГТО организовать расписание, создать тренировки, отслеживать успехи!
				</p>
				<div className="banner__links">
					<a href={Contacts.appStore} rel="noopener noreferrer" target="_blank">
						<img alt="Установи приложение «Стань чемпионом» для iphone из apple store" src={appStore} />
					</a>
					<a href={Contacts.playMarket} rel="noopener noreferrer" target="_blank">
						<img alt="Установи приложение «Стань чемпионом» для android из play market" src={googlePlay} />
					</a>
				</div>
			</div>
			<div className="banner__img">
				<img alt="" src={phoneBg} />
			</div>
			<Link
				to="#"
				className="banner__btndown"
				onClick={(e) => scrollToElement(elementSelectorForScroll, e as any)}
			>
				<img alt="" src={buttomBg} />
			</Link>
		</div>
	</section>
);

export default Banner;
