import React from "react";

import ProfileTypes from "../constants/profileTypes";
import Themes from "../constants/themes";

export interface IFeature {
	title: string;
	image: string;
	text: string;
	button?: {
		link: string;
		text: string;
	};
}

interface IFeaturesProps {
	profileType: ProfileTypes;
	list: Array<IFeature>;
}

const Features = (props: IFeaturesProps) => {
	const { list, profileType } = props;
	const themeProfile = Themes[profileType] || Themes[ProfileTypes.Organization];
	return (
		<>
			{list.map((elem, index) => (
				<section key={index} className="container bannertext">
					<div
						className={[
							"bannertext__img",
							index % 2 ? "bannertext__img--right" : "bannertext__img--left",
							themeProfile.backgroundSecondary
						].join(" ")}
					>
						<img alt={`Стань чемпионом! - ${elem.title}`} src={elem.image} />
					</div>
					<div className="bannertext__content">
						<div>
							<h3 className="bannertext__title">{elem.title}</h3>
							<p>{elem.text}</p>
							{elem.button && (
								<a href={elem.button.link} className={["button", themeProfile.background].join(" ")}>
									{elem.button.text}
								</a>
							)}
						</div>
					</div>
				</section>
			))}
		</>
	);
};

export default Features;
