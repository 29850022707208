import { Link } from "gatsby";
import React from "react";

import ProfileTypes from "../constants/profileTypes";
import Themes from "../constants/themes";

export interface ITab {
	value: ProfileTypes;
	hash: string;
	title: string;
	icon: string;
	content: Array<{
		title: string;
		icon: string;
		text: Array<string>;
	}>;
}

interface ITabsProps {
	tabs: Array<ITab>;
	profileType: ProfileTypes;
	onChangeProfileType: (value: ProfileTypes) => void;
	customStyles?: {
		container?: string;
	};
}

const Tabs = ({ tabs, profileType, onChangeProfileType, customStyles = {} }: ITabsProps) => {
	const themeProfile = Themes[profileType] || Themes[ProfileTypes.Organization];

	const tabActive = tabs.find((tab) => tab.value === profileType);
	const tabActiveContent = tabActive && tabActive.content;

	const clickTab = (tabSelected: ITab, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		onChangeProfileType(tabSelected.value);
		// Добавляем в урл хеш
		window.history.pushState(null, "", `#${tabSelected.hash}`);
		e.preventDefault();
	};

	return (
		<section className={`tabs ${themeProfile.active} ${customStyles.container}`}>
			<ul className="tabs__categories">
				{tabs.map((tab) => (
					<li key={tab.value} className={tab.value === profileType ? "active" : ""}>
						<Link to={`#${tab.hash}`} onClick={(e) => clickTab(tab, e as any)}>
							<i className={`tabs__category_icon icon ${tab.icon}`} />
							<span>{tab.title}</span>
						</Link>
					</li>
				))}
			</ul>
			<div className="tabs__content">
				{tabActiveContent &&
					tabActiveContent.map((elem, index) => (
						<div key={index} className="tabs__content_element">
							<i className={`tabs__content_icon icon ${elem.icon}`} />
							<div>
								<span className="tabs__content_title">{elem.title}</span>
								{elem.text.map((textP, indexText) => (
									<p key={indexText}>{textP}</p>
								))}
							</div>
						</div>
					))}
			</div>
		</section>
	);
};

export default Tabs;
