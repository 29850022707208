import React, { useEffect, useState } from "react";

import calendarOrgBanner from "../../static/images/banners/calendar-org.png";
import chatOrgBanner from "../../static/images/banners/chat-org.png";
import profileBanner from "../../static/images/banners/profile.png";

import calendarCoachBanner from "../../static/images/banners/calendar-coach.png";
import chatCoachBanner from "../../static/images/banners/chat-coach.png";
import trainingLogBanner from "../../static/images/banners/training-log.png";

import calendarSportsmanBanner from "../../static/images/banners/calendar-sportsman.png";
import chatSportsmanBanner from "../../static/images/banners/chat-sportsman.png";
import mapSportsmanBanner from "../../static/images/banners/map-sportsman.png";

import calendarParentBanner from "../../static/images/banners/calendar-parent.png";
import chatParentBanner from "../../static/images/banners/chat-parent.png";
import searchParentBanner from "../../static/images/banners/search-parent.png";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Banner from "../components/banner";
import ContactsForm from "../components/contactsForm";
import Features, { IFeature } from "../components/features";
import Installation from "../components/installation";
import Tabs, { ITab } from "../components/tabs";

import { PageRendererProps } from "gatsby";
import ProfileTypes from "../constants/profileTypes";

const tabs: Array<ITab> = [
	{
		title: "Организациям",
		hash: "organization",
		icon: "icon--organization_line",
		value: ProfileTypes.Organization,
		content: [
			{
				icon: "icon--follow",
				title: "Расписание",
				text: [
					"Создание и поддержка актуального расписания тренировок с автоматической отправкой уведомлений об изменениях. Отслеживание посещений и успехов участников."
				]
			},
			{
				icon: "icon--web_planning",
				title: "Планирование",
				text: [
					"Добавление в расписание значимых спортивных событий страны и мира и создание собственных соревнований городского и регионального уровня."
				]
			},
			{
				icon: "icon--contacts-edit",
				title: "Запись",
				text: [
					"Возможность собирать заявки в школу и на мероприятие в стандартной форме с полными данными спортсмена и законного представителя."
				]
			},
			{
				icon: "icon--search_subscribers",
				title: "Профиль",
				text: [
					"Представление организации спортсменам  и их родителям в приложении. Информация о способах связи, площадках, адресах проведения мероприятий, заслугах, правилах, и т.п."
				]
			},
			{
				icon: "icon--coach_line",
				title: "Тренеры",
				text: ["Хранение информации по тренерам  их заслугам, контактам, образовании."]
			},
			{
				icon: "icon--chat_0",
				title: "Коммуникация",
				text: [
					"Быстрое решение вопросов через чаты между тренером и спортсменом,  в чате организации и в комнатах по мероприятиям."
				]
			}
		]
	},
	{
		title: "Тренерам",
		hash: "coach",
		icon: "icon--coach_line",
		value: ProfileTypes.Coach,
		content: [
			{
				icon: "icon--follow",
				title: "Расписание",
				text: [
					"Создание и поддержка актуального расписания тренировок с автоматической отправкой уведомлений  об изменениях участникам."
				]
			},
			{
				icon: "icon--icon-group",
				title: "Группы",
				text: ["Хранение контактов спортсменов и упорядочение их по группам. Связь с профилями спортсменов."]
			},
			{
				icon: "icon--web_accounting",
				title: "Учет",
				text: [
					"Регистрация посещений участников занятий. Отслеживание уровня спортивной нагрузки через достижение целей тренировки."
				]
			},
			{
				icon: "icon--contacts-edit",
				title: "Запись",
				text: [
					"Возможность собирать заявки на занятия в стандартной форме с полными данными спортсмена и законного представителя."
				]
			},
			{
				icon: "icon--search_subscribers",
				title: "Профиль",
				text: ["Информация о заслугах, достижениях, образовании тренера. Контакты для быстрой связи."]
			},
			{
				icon: "icon--chat_0",
				title: "Коммуникация",
				text: ["Быстрое решение вопросов со спортсменами или родителями через личные чаты."]
			}
		]
	},
	{
		title: "Спортсменам",
		hash: "sportsman",
		icon: "icon--sportsman",
		value: ProfileTypes.Sportsman,
		content: [
			{
				icon: "icon--Glyphs-2-serch",
				title: "Поиск",
				text: ["Найти спортивную школу, клуб, секцию, тренера по различным критериям."]
			},
			{
				icon: "icon--web_check-list",
				title: "Выбор",
				text: [
					"Используйте интерактивную карту, отзывы, рейтинги, информацию профилей для выбор тренеров и спортивных организаций."
				]
			},
			{
				icon: "icon--follow",
				title: "Расписание",
				text: [
					"Создание и поддержка личного расписания тренировок, запись на тренировки и в спортивные организации."
				]
			},
			{
				icon: "icon--search_subscribers",
				title: "Профиль",
				text: ["Хранение информации о достижениях спортсмена, контакты с друзьями, тренерами."]
			},
			{
				icon: "icon--chat_0",
				title: "Коммуникация",
				text: [
					"Быстрое решение вопросов с тренерами в личных чатах. Чаты на спортивные темы и чаты с друзьями."
				]
			},
			{
				icon: "icon--gto",
				title: "ГТО",
				text: ["Узнать нормативы испытаний и выбрать место для сдачи ГТО."]
			}
		]
	},
	{
		title: "Родителям",
		hash: "parent",
		icon: "icon--contact-parent",
		value: ProfileTypes.Parent,
		content: [
			{
				icon: "icon--Glyphs-2-serch",
				title: "Поиск",
				text: ["Найти спортивную школу, клуб, секцию, тренера для ваших детей по различным критериям."]
			},
			{
				icon: "icon--web_check-list",
				title: "Выбор",
				text: [
					"Используйте интерактивную карту, отзывы, рейтинги, информацию профилей для выбор тренеров или спортивной школы."
				]
			},
			{
				icon: "icon--icon-free-workout",
				title: "Тренировки",
				text: [
					"Будьте в курсе изменений в расписании вашего ребенка. Отслеживайте нагрузку и динамику его спортивных достижений."
				]
			},
			{
				icon: "icon--search_subscribers",
				title: "Профиль",
				text: ["Хранение информации о достижениях ребенка."]
			},
			{
				icon: "icon--chat_0",
				title: "Коммуникация",
				text: [
					"Обсуждайте с тренером все вопросы по тренировочному процессу. Обращайтесь в спортшколу за дополнительной информацией."
				]
			},
			{
				icon: "icon--gto",
				title: "ГТО",
				text: ["Узнать нормативы испытаний и выбрать место для сдачи ГТО."]
			}
		]
	}
];

const banners: { [key: number]: Array<IFeature> } = {
	[ProfileTypes.Organization]: [
		{
			image: calendarOrgBanner,
			title: "Создание и ведение расписания занятий",
			text:
				"“Стань чемпионом” помогает вам создавать тренировки, организовать их в расписание, отслеживать посещение и успехи спортсменов! Уведомления не дадут вашим спортсменам пропустить тренировки и изменения.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: chatOrgBanner,
			title: "Коммуникация через встроенные чаты",
			text:
				"Быстро ответить клиенту в чате. Организовать группу с участниками мероприятия или упростить общение между тренерами и спортсменам призвана механика чатов.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: profileBanner,
			title: "Полный профиль Вашей организации",
			text:
				"Разместите наиболее полную информацию об организации в приложении. Описание, контактная информация, адреса, заслуги, уникальный аватар — все это поможет пользователям быстро найти вашу организацию, сделать выбор и записаться на занятия.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		}
	],
	[ProfileTypes.Coach]: [
		{
			image: calendarCoachBanner,
			title: "Расписание занятий всегда под рукой",
			text:
				"“Стань чемпионом” помогает вам создавать разнообразные виды тренировок и добавлять в них участников. Спортсмены всегда в курсе изменений в расписании.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: trainingLogBanner,
			title: "Группы и учет нагрузок",
			text:
				"Объединяйте спортсменов в группы по различным признакам. Определяйте цели тренировок и ведите учет спортивных нагрузок для спортсменов.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: chatCoachBanner,
			title: "Коммуникация через чаты",
			text:
				"Быстро ответить спортсмену в личном чате. Организовать группу с участниками мероприятия для обсуждения тонкостей тренировочного процесса позволят встроенные чаты.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		}
	],
	[ProfileTypes.Sportsman]: [
		{
			image: calendarSportsmanBanner,
			title: "Ведение личного расписания",
			text:
				"Храните личные тренировки и другие мероприятия в одном расписании. Это поможет следить за важными спортивными событиями. Уведомления не дадут пропустить изменения в ваших тренировках!",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: mapSportsmanBanner,
			title: "Выбор тренера, удобного места тренировок",
			text:
				"Выбирайте нужное в удобном районе с помощью интерактивной карты, поиска и фильтров. Используйте рейтинги и отзывы для выбора наиболее удобного времени и места тренировок.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: chatSportsmanBanner,
			title: "Коммуникация через встроенные чаты",
			text:
				"Общайтесь с друзьями на спортивные темы во встроенных чатах. Используйте личные сообщения для быстрой связи с тренером или спортивной организацией.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		}
	],
	[ProfileTypes.Parent]: [
		{
			image: calendarParentBanner,
			title: "Личное расписание и журнал посещений",
			text:
				"“Стань чемпионом” помогает вам хранить личные тренировки и другие мероприятия в одном расписании. Будьте в курсе тренировок своего ребенка, его посещений и выполнения графиков спортивной нагрузки",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: searchParentBanner,
			title: "Инструменты поиска",
			text:
				"Выбирайте нужное в удобном районе с помощью интерактивной карты, поиска и фильтров. Используйте рейтинги и отзывы для выбора наиболее удобного времени и места тренировок.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		},
		{
			image: chatParentBanner,
			title: "Быстрое общение",
			text:
				"В приложении вы найдете телефоны, электронные адреса, группы в социальных сетях, прямые чаты с организациями и тренерами.",
			button: {
				text: "Установить приложение",
				link: "#installation"
			}
		}
	]
};

const IndexPage = (props: PageRendererProps) => {
	const [profileType, setProfileType] = useState(ProfileTypes.Organization);
	const bannersActiveProfileType = banners[profileType];

	const changeProfileType = (value: ProfileTypes) => {
		setProfileType(value);
	};

	useEffect(() => {
		const hash = props.location.hash;
		if (hash.length > 0) {
			const name = hash.slice(1);

			// По хешу определяем активный тип профиля
			const tabActive = tabs.find((tab) => tab.hash === name);
			if (tabActive) {
				setProfileType(tabActive.value);
				return;
			}

			// По хэшу чтоб переходил, если страница сразу с хэшем открывается, иначе не переходит
			try {
				const element = document.querySelector(`a[name=${name}]`);
				if (element) {
					element.scrollIntoView({
						behavior: "smooth",
						block: "start"
					});
				}
			} catch (err) {
				console.log(`Error querySelector - ${err}`);
			}
		}
	}, []);

	return (
		<Layout withMainTag={false}>
			<SEO title="Стань чемпионом!" />
			<Banner elementSelectorForScroll=".maininfo" />
			<main>
				<article className="maininfo container">
					<h2 className="maininfo__title">Какие проблемы решаются?</h2>
					<Tabs
						tabs={tabs}
						profileType={profileType}
						onChangeProfileType={changeProfileType}
						customStyles={{ container: "maininfo__content" }}
					/>
					<h2 className="maininfo__title">С помощью чего решаются проблемы?</h2>
					<Features list={bannersActiveProfileType} profileType={profileType} />
				</article>
				<a id="installation" />
				<Installation />
			</main>
			<a id="contacts" />
			<ContactsForm profileType={profileType} />
		</Layout>
	);
};

export default IndexPage;
