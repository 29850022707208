import React from "react";
import appStore from "../../static/images/appstore.png";
import regBanner from "../../static/images/banners/reg.png";
import googlePlay from "../../static/images/googleplay.png";

import Contacts from "../constants/contacts";

const Installation = () => (
	<section className="container installation">
		<div className="installation__content">
			<h2 className="installation__title">Как установить?</h2>
			<p>
				Скачайте приложение из App Store или из Google Play. Зарегистрируйтесь в приложении по номеру телефона.
			</p>
			<div className="installation__links">
				<a href={Contacts.appStore}>
					<img alt="Установи приложение «Стань чемпионом» для iphone из apple store" src={appStore} />
				</a>
				<a href={Contacts.playMarket}>
					<img alt="Установи приложение «Стань чемпионом» для android из play market" src={googlePlay} />
				</a>
			</div>
		</div>
		<div className="installation__img">
			<img alt="Стань чемпионом! - регистрация" src={regBanner} />
		</div>
		<div className="installation__links installation__links--sm">
			<a href={Contacts.appStore} rel="noopener noreferrer">
				<img alt="Установи приложение «Стань чемпионом» для iphone из apple store" src={appStore} />
			</a>
			<a href={Contacts.playMarket} rel="noopener noreferrer">
				<img alt="Установи приложение «Стань чемпионом» для android из play market" src={googlePlay} />
			</a>
		</div>
	</section>
);

export default Installation;
